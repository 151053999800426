export default {
  lang: '繁',
  test: '测试',
  AddToCart: '加入购物车',
  changeLangSuccess: '语言切换成功！',
  changeCurSuccess: '货币切换成功！',
  Input: {
    email: '请输入正确的邮箱',
    number: '请输入数字',
    input: '请输入',
    text: '',
    date: '请选择日期',
    password: '两次输入密码不一致',
    textarea: '',
    emailNotFound: '邮箱不存在'
  },
  Message: {
    Logout: '您尚未登陆或已登录超时，请重新登陆',
    Message: '消息',
    Confirm: '确认',
    Cancel: '关闭',
    SucceedInOperating: '操作成功',
    noRMBStripe: '货币RMB不支持Stripe支付，请选择其他支付方式或切换货币模式'
  },
  Account: {
    MemberCenter: '会员中心',
    MemberInformation: '用户信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最爱',
    MyOrder: '我的订单',
    DeliveryAddress: '送货地址',
    Logout: '登出',
    Welcome: '欢迎!',
    SISA: '选择成功',
    PersonCenter: '个人中心'
  },
  RegNPay: {
    BackText: '返回修改',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确认送出',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}档案',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '打印',
    PlsPayWay: '请选择付款方式',
    PreviewTitleText: '请再次确认页面',
    FormatErrorText: '请输入正确电邮地址',
    RequiredText: '请检查你的答案'
  },
  product: {
    ProductCode: '产品代码',
    buy: '立即购买',
    addToCart: '加入购物车',
    buyNow: '立即购买',
    favorite: '加入我的清单',
    priceTitle: '价格',
    countTitle: '数量',
    youWouldLike: '你可能喜欢',
    moreTips: '点击加载更多',
    none: '没有了',
    noneInCat: '当前目录无产品，请选择其他目录',
    notifyMe: '到货通知',
    purchasing: '采购中',
    comments: { title: '商品评论' },
    detail: '产品详情',
    overView: '产品简介',
    specification: '使用帮助',
    attr: { 0: '尺寸', 1: '顔色' },
    SaleOut: '售罄',
    offSale: '已下架',
    Recommend: '会员推荐',
    Remarks: '备注',
    EnterRemaks: '请填写备注',
    up: '上一个',
    down: '下一个',
    logouted: '您已退出登录！',
    loginow: '现在重新登陆？',
    successInAdding: '添加成功',
    successInRemoving: '取消成功',
    ProductIntro: '产品介绍',
    ProductIntroduction: '产品介绍',
    UserReviews: '用户评论',
    OwnerReply: '商家回复',
    per: '第',
    page: '页',
    Screening: '筛选',
    Resetall: '重置所有筛选条件',
    PriceHL: '价格从高至低',
    PriceLH: '价格从低至高',
    Producttitle: '产品列表',
    Paixu: '排序',
    Total: '共',
    Product: '个产品',
    ProductDetail: '产品详情'
  },
  pyramidSelling: {
    ps: '传销'
  },
  Shoppingcart: {
    ShoppingcartTitle: '购物车',
    Merchant: '商家',
    Product: '产品',
    Quantity: '数量',
    Price: '价格',
    Total: '商品总价',
    CheckoutList: '确认订单',
    Checkout: '安全结算',
    goTOCart: '前往购物车',
    vat: '包含增值税',
    None: '购物车里空空如也，现在去购买？',
    more: '更多',
    hide: '收起'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '会员登入',
    NewUser: '新用户',
    RegNow: '现在注册',
    ForgetPwd: '忘记密码',
    RememberMe: '记住我',
    FaceBookUse: '使用facebook账户登录',
    UserName: '登录用户名/邮箱地址',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确邮箱地址',
    LoginPwd: '请输入密码',
    LoginoldPwd: '密码不正确',
    Loginerror: '账号或密码不正确',
    LoginSucceeded: '登入成功！'
  },
  Register: {
    RegisterTitle: '注册会员',
    UserEmail: '邮箱',
    or: '或',
    UserRegPassword: '密码',
    UserNewPassword: '新密码',
    UserOldPassword: '旧密码',
    UserRegTips: '最少6个由字母或数字组成的字符',
    UserConfirmPassword: '确认密码',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '联系电话',
    UserBirthDate: '出生日期',
    UserGender: '性别',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '语言',
    UserLanguageE: '英文',
    UserLanguageT: '繁体',
    UserLanguageS: '简体',
    RegisterBtn: '注册',
    RegisterAgree: '我同意购买条款和使用条款',
    RegisterEmail: '邮箱不能为空',
    RegisterEmail01: '请输入正式的邮箱格式',
    RegisterPassword: '请输入密码',
    RegisterNewPassword: '请再次输入密码',
    RegisterNewPassword01: '两次输入密码不一致',
    RegisterFirstName: '请输入您的姓名',
    RegisterLastName: '请输入您的名称',
    RegisterAgree_check: '请勾选',
    RegisterMobile: '请输入您的手提电话',
    RegisterSuccess: '注册成功',
    RegisterFail: '注册失败',
    RegisterWelcome: '恭喜您,注册成功',
    RegisterTips: '此电子邮箱尚未注册，请立即注册成为会员！'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '选择送货地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收货地址',
    PostalCode: '邮政编码',
    Area: '地区',
    Province: '省份',
    District: '区份',
    Address: '地址',
    DefaultAddress: '设为默认送货地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '联系电话',
    Mobile: '手提电话',
    Addressprovince: '请选择省份',
    AddressArea: '请选择地区',
    Detail: '街道/屋苑/楼层/室'
  },
  MyCoupon: {
    MyCoupon: '我的优惠券',
    Coupon: '优惠券',
    PeriodOfValidity: '有效期',
    NoCoupon: '暂无优惠券',
    CouponStatus: '优惠券状态',
    SelectCouponStatus: '请选择优惠券状态',
    TotalNum: '共有',
    Piece: '张',
    NotUse: '可用',
    DoNotUse: '不可用',
    Used: '已用',
    Among: '其中',
    All: '全部'
  },
  MyFavorite: {
    MyFavorite: '我的最爱',
    LearnMore: '了解更多',
    RemoveProduct: '移除产品',
    RemoveSuccess: '移除成功',
    AddSuccess: '加入成功'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '总记录数 ：',
    UnreadWithSymbol: '未读记录数 ：',
    MsgUnreadOnly: '只看未读消息',
    MsgMarkAsRead: '标记为已读',
    CheckAllOrNot: '全选/反选',
    MsgSender: '发信人',
    MsgSubject: '主题',
    MsgSenderDate: '发信时间',
    MsgReadStatus: '状态',
    Details: '了解详情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '发送',
    Unread: '未读',
    Read: '已读',
    UpdateSucceeded: '更新成功!'
  },
  Forgetpassword: {
    ForgetPassword: '忘记密码',
    ResetPwdTips: '请输入您用于注册的电子邮件，然后点击“下一步”继续',
    LoginNameEmail: '邮箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密码已发送到您的邮箱，请查看。',
    NotEmail: '收不到重设密码电邮',
    ToReEnter: '按此重新填写',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确的邮箱地址',
    ResetPwd: '重设密码'
  },
  MemberInfo: {
    MemberInfoTitle: '用户信息',
    UserAccount: '用户账号',
    ModifyPassword: '修改密码',
    EnterUserName: '请输入名字',
    EnterUserLastName: '请输入姓氏',
    EnterUserPhone: '请输入联系电话',
    EnterUserBrithDate: '请选择出生日期',
    EnterOldPwd: '请输入密码',
    EnterNewPwd: '请输入新密码',
    EnterComfirmPwd: '请输入确认密码',
    EnterNumber: '请输入数字',
    PwdNotCorrect: '两次密码不一致',
    AgreeEmail: '本人同意天才儿童书店透过电邮地址向我发送推广信息',
    DDMM: '日/月'
  },
  Order: {
    OrderTitle: '我的订单',
    OrderDetail: '订单详情',
    OrderPay: '立即支付',
    OrderCancel: '订单取消',
    OrderComplete: '订单完成',
    OrderClose: '订单关闭',
    CartSubtotal: '小计',
    DeliveryCharge: '运输费用',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    OrderDateTime: '订单日期及时间 ',
    OrderNumber: '订单编号',
    OrderSearch: '订单搜索',
    EnterProductName: '请输入产品名称',
    Items: '项',
    OrderComment: '评价',
    PromotionCode: '促销码',
    RecommendationCode: '会员推荐码',
    CommodityPrice: '商品总价',
    Full: '买满',
    Minus: '减',
    Hit: '减',
    Precent: '%',
    Discount: '优惠价格',
    Cancel: '取消',
    Paid: '已付款',
    Unpaid: '未付款'
  },
  Concessions: {
    Cannot: '优惠券与优惠码不可不可同时使用'
  },
  Payment: {
    ReturnHomePage: '返回首页',
    PayStatus: '支付状态',
    PayFailTips: '本次支付失败，请检查您的支付户口后重新尝试。',
    PaysuccessTips: '本次支付成功'
  },
  CheckOut: {
    Ordersummary: '订单摘要',
    Input: '请输入',
    expiryDate: '过期日期',
    awsl: 'awsl',
    expressError: '快递异常！请选择其他快递。',
    pay: '支付',
    CheckOutTitle: '确认订单',
    Merchant: '商家',
    Product: '产品',
    Qty: '数量',
    Price: '价钱',
    SplitDelivery: '拆单',
    ShippingMethod: '送货方式',
    PleaseSelect: '请选择',
    Selected: '已选择',
    Name: '名字',
    Phone: '电话',
    Address: '地址',
    Pickup: '自取',
    Change: '新增或更改地址',
    DeliveryCharge: '运输费用',
    SubTotal: '商品小计',
    PayBy: '支付方式',
    Couponcode: '优惠码',
    confirm: '确认',
    Total: '共',
    BacktoShoppingCart: '返回购物车',
    Last: '上一页',
    Checkout: '确认订单',
    Next: '下一页',
    Delivery: '配送方式与运费',
    SelectDeliveryAddress: '选择送货地址',
    LastName: '姓氏',
    Edit: '更改地址',
    Delete: '删除地址',
    Select: '选取',
    NewAddress: '添加新的收货地址',
    PickUp: '自取',
    FirstName: '名字',
    ZipCode: '邮政编码',
    Mobile: '手机',
    Country: '地区',
    Province: '地区/省份',
    City: '区份',
    Close: '关闭',
    PickupDate: '自取日期',
    PickupTime: '自取时间',
    Morning: '早上',
    Afternoon: '下午',
    PickAddressError: '自提信息必须完整',
    AddressLock: '地址已被选中不可删除',
    CompanyName: '门店',
    CompanyPhone: '门店电话',
    CompanyAddress: '门店地址',
    PickupAddress: '自取地址',
    Save: '保存',
    PleaseChoose: '请选择',
    PleaseSelectdate: '请选择日期',
    PleaseSelecttime: '请选择时间',
    ConfirmationOfOrder: '确认订单',
    Concessions: '优惠信息',
    PaymentMethod: '支付方式',
    Complete: '完成',
    OrderPreview: '订单预览',
    CandP: '优惠券与促销码不能同时使用',
    promotionCodeError: '请输入正确的促销码',
    Use: '使用',
    As: '作爲',
    PaymentMethodError: '支付方式尚未选择！',
    OrderSent: '订单已发出',
    PaymentProcess: '请继续完成付款程序'
  },
  Address: {
    Country: '请选择国家',
    Province: '请选择省份',
    Address: '请填写详细地址'
  },
  MemberPoints: {
    MemberPoints: '会员积分',
    PointsDetail: '积分明细',
    PointsIncome: '积分收入',
    PointsSpending: '积分支出',
    OrderTime: '订单时间',
    OrderNo: '订单编号',
    Income: '收入',
    Spending: '支出',
    IncomeSpending: '收入/支出',
    DetailedDescription: '详细帮助',
    TotalPoints: '总积分',
    Hello: '您好',
    Equals: '等于',
    Integral: '积分',
    PointsTips: '积分使用常见问题',
    PointsType: '积分类别',
    Points: '积分',
    PointsMode: 'PointsMode'
  },
  Cms: {
    AboutUS: '关于我们',
    OurStores: '门市',
    BusinessHours: '营业时间',
    BigSales: '热销产品',
    WhatNews: '今期流行'
  },
  promotion: {
    enter: '点击进入',
    main: '天才儿童最佳之选',
    monterssori: {
      title1: '蒙特梭利教学法',
      title2: '培养幼儿自觉主动的学习和探索精神',
      banner1: '做自己的老师',
      banner2: '让儿童自发性地主动学习',
      banner3: '自己构建完善的人格'
    }
  },
  home: {
    News: '新闻',
    More: '更多',
    Category: '产品目录',
    Weaccept: '我们接受',
    Order: '订购',
    TelSearch: '电话查询',
    AllProduct: '全部产品',
    ResetProduct: '重置',
    Address: '地址',
    ViewDetail: '查看详情',
    Screening: '筛选',
    Resetall: '重置所有筛选条件',
    PriceHL: '价格从高至低',
    PriceLH: '价格从低至高',
    Thatsall: '以上是全部内容',
    WeAcceptPay: '我们接受以下付款方式'
  },
  Action: {
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情',
    PaymentSuccessTips: '支付成功',
    PaymentFailTips: '支付失败',
    Checkout: '确认订单',
    Save: '保存',
    PromotionCodeTips: '请输入优惠码',
    Share: '立即分享',
    LoadMore: '加载更多',
    FacebookLogin: 'Facebook 登入',
    HotPot: '火锅类',
    BBQ: '烧烤类',
    Dishes: '餸菜类',
    Package: '套餐',
    ProductSearch: '产品搜寻',
    Total: '共',
    Products: '个产品',
    NowBook: '现在预约',
    NowOrder: '立即预约'
  },
  accountHeader: {
    MemberFile: '会员档案',
    Favorite: '喜爱项目',
    GrantsCredits: '积分奖励',
    MyOrder: '我的订单',
    ECoupon: '电子礼券',
    MembershipDiscount: '会员优惠'
  },
  regnpay: {
    successful: '感谢您的提交',
    reply: '我们会尽快回复您',
    ContactUs: '联络我们'
  },
  messageTips: {
    NoContent: '暂无内容',
    NoComments: '暂无评论',
    NoProduct: '对不起，没有你搜索的产品'
  }
};
