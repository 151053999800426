export default {
  lang: 'English',
  test: 'test',
  AddToCart: 'Add To Cart',
  changeLangSuccess: 'Language switch successful !',
  changeCurSuccess: 'Currency switch successful !',
  Input: {
    email: 'Please enter the correct email',
    number: 'Please enter a number',
    input: 'Please input ',
    text: '',
    date: 'Please enter a date',
    password: 'Two inconsistent input passwords',
    textarea: '',
    emailNotFound: 'Email Not Found',
    logopassword: 'Please input passwords',
    confirmpassword: 'Two inconsistent input passwords',
    nomessage: 'No Message',
    phoneincorrect: 'The phone number format is incorrect'
  },
  Message: {
    Logout: 'Timeout ! login now ?',
    Message: 'Message',
    Confirm: 'Ok',
    Cancel: 'Close',
    SucceedInOperating: 'Succeed in Operating',
    SavedSuccessfully: 'Saved successfully',
    Loading: 'Page Loading, Please Wait',
    ViewDetail: 'VIEW DETAILS',
    FollowUs: 'Follow Us',
    Evidence: 'Evidence',
    UploadEvidence: 'Upload Evidence',
    OrderNumber: 'Order Number',
    Remark: 'Remark',
    Image: 'Image',
    SubmitSuccess: 'Submitted Successfully',
    SubmitFailed: 'Submit Failed',
    DeliveryDate: 'Delivery Date',
    DeliveryTime: 'Delivery Time',
    DeliveryInstructions: 'Delivery Instructions',
    DeliveryMark: 'Please make a reservation one day in advance. The order is cut off at 10 PM every day. The Package over 800 packages, urban delivery.Only Payment complete before 10PM can be delivered the next day',
    All: 'All',
    noRMBStripe: 'Currency RMB does not support Stripe payment, please choose another payment method or switch currency mode'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'Confirm and Send',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Account: {
    MemberCenter: 'Member Center',
    MemberInformation: 'Member Information',
    MyMessages: 'My Messages',
    MyFavorite: 'My Favorite',
    MyOrder: 'My Order',
    DeliveryAddress: 'Delivery Address',
    Logout: 'Logout',
    Welcome: 'WELCOME!',
    SISA: 'Succeed in Choosing',
    PersonCenter: 'Personal Center',
    HealthButler: 'Health Butler'
  },
  product: {
    ProductCode: 'Product Code',
    buy: 'Buy Now',
    addToCart: 'Add To Cart',
    buyNow: 'Buy now',
    favorite: 'Favorite',
    priceTitle: 'price',
    countTitle: 'count',
    youWouldLike: 'You May Also Like',
    moreTips: 'Click to load more',
    none: 'None',
    noneInCat: 'no prodcut belong to this catalog',
    notifyMe: 'Notify Me',
    purchasing: 'purchasing',
    comments: { title: 'Comments' },
    detail: 'detail',
    overView: 'overView',
    specification: 'Specification',
    attr: { 0: 'Size', 1: 'Color' },
    SaleOut: 'Sold Out',
    offSale: 'off sale',
    Recommend: 'Recommend',
    Remarks: 'Remarks',
    EnterRemaks: 'please enter remarks',
    up: 'Last',
    down: 'Next',
    logouted: 'you are logouted!',
    loginow: 'login now',
    successInAdding: 'succeed in adding',
    successInRemoving: 'succeed in removing',
    ProductIntro: 'Product introduction',
    ProductIntroduction: 'Product introduction',
    UserReviews: 'User reviews',
    OwnerReply: 'Owner reply',
    per: 'page',
    page: '',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Producttitle: 'Product List',
    Paixu: 'Sort',
    Total: 'Total',
    Product: 'Products',
    ProductDetail: 'Product Detail',
    Unit: 'Unit'
  },
  Shoppingcart: {
    ShoppingcartTitle: 'Shopping Cart ',
    Merchant: 'Merchant',
    Product: 'Product',
    Quantity: 'Quantity',
    Price: 'Price',
    Total: 'Total',
    CheckoutList: 'Check Out',
    Checkout: 'Check Out',
    goTOCart: 'To Shopping Cart',
    vat: 'Include VAT',
    None: 'No article in your Shopping Cart!Shopping now.',
    more: 'more',
    hide: 'hide'
  },
  Login: {
    doLogin: 'Login',
    LoginTitle: 'Login',
    NewUser: 'New buyer',
    RegNow: 'Register now',
    ForgetPwd: 'Forget Password',
    RememberMe: 'Remember Me',
    FaceBookUse: 'Login with FaceBook',
    UserName: 'Login Name/Email',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    LoginPwd: 'Please enter your password',
    LoginoldPwd: 'Incorrect password',
    Loginerror: 'Incorrect account or password',
    LoginSucceeded: 'Login Succeeded',
    Account: 'Account'
  },
  Register: {
    RegisterTitle: 'Registration',
    UserEmail: 'Email',
    or: 'or',
    UserRegPassword: 'Password',
    UserNewPassword: 'New Password',
    UserOldPassword: 'Old Password',
    UserRegTips: 'Min.6 characters with letters and numbers',
    UserConfirmPassword: 'Confirm',
    UserFirstName: 'First Name',
    UserLastName: 'Last Name',
    UserContactNumber: 'Phone',
    UserBirthDate: 'Birth Date',
    UserGender: 'Gender',
    UserMale: 'Male',
    UserFemale: 'Female',
    UserLanguage: 'Language',
    UserLanguageE: 'English',
    UserLanguageT: 'Traditional Chinese',
    UserLanguageS: 'Simplified Chinese',
    RegisterBtn: 'Register',
    RegisterAgree: 'I agree to the terms of purchase and terms of use',
    RegisterEmail: 'Email cannot be empty',
    RegisterEmail01: 'Please enter a formal mailbox format',
    RegisterPassword: 'Please enter your password',
    RegisterNewPassword: 'Please enter your password again',
    RegisterNewPassword01: 'Two inconsistent input passwords',
    RegisterFirstName: 'Please enter your name',
    RegisterLastName: 'Name please enter your name',
    RegisterAgree_check: 'Please check',
    RegisterMobile: 'Please enter your phone',
    RegisterSuccess: 'Register Succeeded',
    RegisterFail: 'Register Failed',
    RegisterWelcome: 'Congratulations on your successful registration',
    RegisterTips: 'This email address has not been registered, register to become a member immediately!',
    Login: 'Login now!',
    Registerdigital: 'Length from 8 to 20 must contain uppercase letters, lowercase letters and numbers'
  },
  DeliveryAddress: {
    AddAddress: 'Add address',
    ChooseDeliveryAddress: 'Select delivery address',
    EditBtn: 'Edit',
    DeleteBtn: 'Delete',
    AddDeliveryAddress: 'New Address',
    PostalCode: 'PostalCode',
    Area: 'Area',
    Province: 'Province',
    District: 'District',
    Address: 'Address',
    DefaultAddress: 'Set as default delivery address?',
    SaveBtn: 'Save',
    ResetBtn: 'Reset',
    CloseBtn: 'Close',
    YesBtn: 'Yes',
    NoBtn: 'No',
    AddFirstName: 'First Name',
    AddLastName: 'Last Name',
    UserContactNumber: 'Phone',
    Mobile: 'Mobile',
    Addressprovince: 'Please choose the province',
    AddressArea: 'Please choose the area',
    Detail: 'detail'
  },
  MyCoupon: {
    MyCoupon: 'My Coupon',
    Coupon: 'Coupon',
    PeriodOfValidity: 'Time',
    NoCoupon: 'No Coupon',
    CouponStatus: 'Coupon Status',
    SelectCouponStatus: 'Please Slect Coupon Status',
    TotalNum: 'Total',
    Piece: 'Piece',
    NotUse: 'Valid',
    DoNotUse: 'Invalid',
    Used: 'Invalid',
    Among: 'Among',
    All: 'All'
  },
  MyFavorite: {
    MyFavorite: 'My Favorite',
    LearnMore: 'Learn More',
    RemoveProduct: 'Remove Product',
    RemoveSuccess: 'Remove Successfully',
    AddSuccess: 'Add Successfully'
  },
  Notification: {
    MyMessages: 'My Messages',
    MsgList: 'Message List',
    TotalRecQtyWithSymbol: 'Total Qty. ：',
    UnreadWithSymbol: 'Unread Qty：',
    MsgUnreadOnly: 'Unread Message Only',
    MsgMarkAsRead: 'Mark as read',
    CheckAllOrNot: 'Check All/Not',
    MsgSender: 'Sender',
    MsgSubject: 'Subject',
    MsgSenderDate: 'Send Date',
    MsgReadStatus: 'Status',
    Details: 'Details',
    More: 'More',
    Back: 'Back',
    MsgContent: 'Content',
    Send: 'Send',
    Unread: 'Unread',
    Read: 'Read',
    UpdateSucceeded: 'Update Succeeded!',
    Check: 'Check',
    Id: 'Id'
  },
  Forgetpassword: {
    ForgetPassword: 'Forget Password',
    ResetPwdTips:
      'Please enter the registered email you used for registration, then click Next to continue',
    LoginNameEmail: 'Email',
    NextStep: 'Next',
    ResetPwdSuccess: 'Temp Password is sended to your email,please check.',
    NotEmail: 'Cannot receive the Activate Email',
    ToReEnter: 'Click here to re-enter',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    ResetPwd: 'Reset Password'
  },
  MemberInfo: {
    MemberInfoTitle: 'Member Info',
    UserAccount: 'User Account',
    ModifyPassword: 'Modify Password',
    EnterUserName: 'Please Enter Fristname',
    EnterUserLastName: 'Please Enter Lastname',
    EnterUserPhone: 'Please Enter Phone',
    EnterUserBrithDate: 'Please Select BrithDate',
    EnterOldPwd: 'Please Enter Old Password',
    EnterNewPwd: 'Please Enter New Password',
    EnterNumber: 'required number',
    EnterComfirmPwd: 'Please Enter Comfirm Password',
    PwdNotCorrect: 'The passwords not match twice',
    AgreeEmail: 'I agree that edtoy will send me promotional information through e-mail address',
    DDMM: 'DD/MM'
  },
  Order: {
    OrderTitle: 'My Order',
    OrderDetail: 'Order Detail',
    OrderPay: 'Pay Now',
    OrderCancel: 'Order Cancel',
    OrderComplete: 'Order Complete',
    OrderClose: 'Order Close',
    CartSubtotal: 'Cart Subtotal',
    DeliveryCharge: 'Delivery Charge',
    TotalPrice: 'Total Price',
    OrderStatus: 'Order Status',
    OrderDateTime: 'Order Date & Time',
    OrderNumber: 'Order Number',
    OrderSearch: 'Order Search',
    EnterProductName: 'Please Enter Product Name',
    Items: 'ITEMS',
    OrderComment: 'Comment',
    PromotionCode: 'Promotion Code',
    RecommendationCode: 'Recommendation Code',
    CommodityPrice: 'Commodity Price',
    Full: 'Buy',
    Minus: 'Free',
    Hit: 'Free',
    Precent: 'Precent off',
    Discount: 'Discount Price',
    Cancel: 'Cancel',
    Paid: 'Paid',
    Unpaid: 'Unpaid'

  },
  Payment: {
    ReturnHomePage: 'Return HomePage',
    PayStatus: 'Pay Status',
    PayFailTips:
      'The payment was fail, pleasse try again after checking of your account.',
    PaysuccessTips: 'The payment was successful.'
  },
  CheckOut: {
    Ordersummary: 'Order Summary',
    Input: 'Input',
    expiryDate: 'Expiry Date',
    awsl: 'awsl',
    expressError: 'Express outage!Please choose others.',
    pay: 'pay',
    CheckOutTitle: 'Check Out',
    Merchant: 'Merchant',
    Product: 'Product',
    Qty: 'Qty',
    Price: 'Price',
    SplitDelivery: 'Split Delivery',
    ShippingMethod: 'Shipping Method',
    PleaseSelect: 'Please select',
    Selected: 'Selected',
    Name: 'Name',
    Phone: 'Phone',
    Address: 'Address',
    Pickup: 'Pick up',
    Change: 'Change',
    DeliveryCharge: 'Delivery Charge',
    SubTotal: 'Sub-Total',
    PayBy: 'Pay By',
    Couponcode: 'Promotion Code',
    confirm: 'Confirm',
    Total: 'Total',
    BacktoShoppingCart: 'Back to Shopping Cart',
    Last: 'Last',
    Checkout: 'Check Out',
    Next: 'Next',
    Delivery: 'Delivery',
    SelectDeliveryAddress: 'Select delivery address',
    LastName: 'Last Name',
    Edit: 'Edit',
    Delete: 'Delete',
    Select: 'Select',
    NewAddress: 'New Address',
    PickUp: 'Pick Up',
    FirstName: 'First Name',
    ZipCode: 'Zip Code',
    Mobile: 'Mobile',
    Country: 'Area',
    Province: 'Region / Province',
    City: 'District',
    Close: 'Close',
    PickupDate: 'Pick up Date',
    PickupTime: 'Pick up Time',
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    PickAddressError: 'Incomplete PickAddress information',
    AddressLock: 'This Address has been Selected',
    CompanyName: 'Company Name',
    CompanyPhone: 'Company Phone',
    CompanyAddress: 'Company Address',
    PickupAddress: 'Pick up Address',
    Save: 'Save',
    PleaseChoose: 'Please Choose',
    PleaseSelectdate: 'Please Select date',
    PleaseSelecttime: 'Please select time',
    ConfirmationOfOrder: 'Confirmation Of Order',
    Concessions: 'Concessions',
    PaymentMethod: 'Payment Method',
    Complete: 'Complete',
    OrderPreview: 'Order Preview',
    CandP: 'Cant use coupon and promotionCode at the same time.',
    promotionCodeError: 'Please enter the correct promotion code',
    Use: 'Use',
    As: 'As',
    OrderSent: 'Order has been sent',
    PaymentProcess: 'Please complete the payment process'
  },
  Concessions: {
    Cannot: 'Offer cannot be used at the same time'
  },
  Address: {
    Country: 'Please choose country',
    Province: 'Please choose province',
    Address: 'Please enter detail address'
  },
  MemberPoints: {
    MemberPoints: 'Member Points',
    PointsDetail: 'Points Detail',
    PointsIncome: 'Points Income',
    PointsSpending: 'Points Spending',
    OrderTime: 'Order Time',
    OrderNo: 'Order Number',
    Income: 'Income',
    Spending: 'Expenditure',
    IncomeSpending: 'Income/Spending',
    DetailedDescription: 'Detailed Description',
    TotalPoints: 'Total Integral',
    Hello: 'Hello',
    Equals: 'Equals',
    Integral: 'Integral',
    PointsTips: 'Frequently asked questions about integral use',
    PointsType: 'Points Type',
    Points: 'Points',
    PointsMode: 'Points Mode'
  },
  Cms: {
    AboutUS: 'About US',
    OurStores: 'OUR STORES',
    BusinessHours: 'Business Hours',
    BigSales: 'BIG SALES',
    WhatNews: 'WHAT’S NEWS'
  },
  promotion: {
    enter: 'enter',
    main: 'The best choice for gifted children',
    monterssori: {
      title1: 'Montessori teaching method',
      title2: 'Develop the habit of independent learning and exploration',
      banner1: 'Be your own teacher',
      banner2: 'Initiative learning',
      banner3: 'build personality'
    }
  },
  home: {
    News: 'News',
    More: 'More',
    Category: 'Category',
    Weaccept: 'We Accept',
    Order: 'Order',
    TelSearch: 'Tel',
    AllProduct: 'All Product',
    ResetProduct: 'Reset',
    Address: 'Address',
    ViewDetail: 'Detail',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Thatsall: 'That is all',
    WeAcceptPay: 'We accept the following terms of payment'
  },
  Action: {
    ReturnHomePage: 'Return Homepage',
    OrderDetails: 'Order Details',
    PaymentSuccessTips: 'Payment Successful',
    PaymentFailTips: 'Payment Fail',
    Checkout: 'Check Out',
    Save: 'Save',
    PromotionCodeTips: 'Please enter the PromotionCode',
    Share: 'Share',
    LoadMore: 'Load More',
    FacebookLogin: 'Facebook Login',
    HotPot: 'Hot Pot',
    BBQ: 'BBQ',
    Dishes: 'Dishes',
    Package: 'Package',
    ProductSearch: 'Product Search',
    Total: 'Total',
    Products: 'Products',
    NowBook: 'BOOK NOW',
    NowOrder: 'BOOK NOW'
  },
  accountHeader: {
    MemberFile: 'Membership File',
    Favorite: 'Favorite',
    GrantsCredits: 'Grants Credits',
    MyOrder: 'My Order',
    ECoupon: 'ECoupon',
    MembershipDiscount: 'Membership Discount'
  },
  regnpay: {
    successful: 'Thank you for your submit',
    reply: 'We will reply you as soon as possible',
    ContactUs: 'Contact Us'
  },
  messageTips: {
    NoContent: 'No Content',
    NoComments: 'No Comments',
    NoProduct: 'Sorry, there are no products you searched for'
  },
  tvp: {
    MyHealthButler: 'My Health Butler',
    tvpinformation: 'Personal Information',
    tvpinformation01: 'Physical Condition',
    tvpregDietary: 'Dietary Evaluation',
    tvpregFood: 'Food Diary',
    tvpregFoodselection: 'Food Selection',
    tvpregEating: 'Eating Habits',
    tvpregExercise: 'Exercise Habits',
    tvpregexpect: 'Desire',
    tvptime: 'It takes 10-15 minutes to complete',
    tvpregFood_1: '(Please fill in the latest dietary information)',
    tvpregFood_2: 'Breakfast',
    tvpregFood_3: 'Time',
    tvpregFood_4: 'Place',
    tvpregFood_5: 'Food name, cooking method, and detailed portion size',
    tvpregFood_6: 'Lunch',
    tvpregFood_7: 'Afternoon Tea',
    tvpregFood_8: 'Dinner',
    tvpregFood_9: 'Evening Dessert',
    tvpregFood_10: 'Morning dessert',
    Email: 'Mailbox',
    setEmail: 'The email you entered already exists. You can enter a password to log in directly',
    Password: 'Password',
    tvpregchinesename: 'Chinese Name',
    tvpregengname: 'English Name',
    tvpregalias: 'Alias',
    Gender: 'Gender',
    Male: 'Male',
    Female: 'Female',
    BirthDate: 'Date of Birth',
    tvpregAge: 'Age',
    tvpregphone: 'Telephone',
    tvpregwhatsapp: 'Can I contact WhatsApp by phone',
    Yes: 'Yes',
    No: 'No',
    tvpregMarital: 'Marital Status',
    Pleaseselect: 'Please Select',
    tvpregOccupation: 'Occupation',
    Occupation_1: 'Full Time',
    Occupation_2: 'Part Time',
    Occupation_3: 'Retirees',
    Occupation_4: 'Housewife',
    Occupation_5: 'Other',
    tvpreglevel: 'Educational Level',
    Educational_1: 'College Degree or above',
    Educational_2: 'Middle School',
    Educational_3: 'Primary',
    Educational_4: 'Other',
    modification: 'Submit Modifications',
    information: 'Modify Information',
    tvpregPrev: 'Previous',
    tvpregNext: 'Next',
    tvpregMedicalrecord: 'Medical Record',
    tvpregDrugs: 'Medications Being Taken',
    tvpregheight: 'Height (please leave blank if unsure)',
    tvpregheight01: 'cm',
    tvpregWeight: 'Weight (please leave blank if unsure)',
    tvpregWeight01: 'kilogram',
    tvpregpast: 'Have there been significant weight changes in the past three months?',
    tvpregDetailed15: 'If so, please provide a detailed explanation.',
    tvpregpast01: 'Weight Gain',
    tvpregpast02: 'Relieve',
    tvpregpast03: 'No',
    tvpregLose16: 'Have you ever lost weight?',
    tvpregLose16_01: 'Have',
    tvpregLose16_02: 'No',
    tvpregNutrition17: 'Have you ever received other physical therapy services',
    tvpreg18_1: 'Upper blood pressure (mmHg) (if any)',
    tvpreg18_2: 'Lower blood pressure (mmHg) (if any)',
    tvpregblood: 'Blood test (if any)',
    tvpregblood19: 'Test Date',
    tvpregblood19_1: 'Total cholesterol (mmol/L)',
    tvpregblood19_2: 'Fasting blood glucose (mmol/L)',
    tvpregblood19_3: 'Triglyceride (mmol/L)',
    tvpregblood19_4: '2-hour blood glucose (mmol/L)',
    tvpregblood19_5: 'High density cholesterol (mmol/L)',
    tvpregblood19_6: 'Low density cholesterol (mmol/L)',
    tvpregblood19_7: 'Glycated hemoglobin (%)',
    tvpregblood19_8: 'Uric acid (mmol/L)',
    tvpregblood19_9: 'Other Remarks',
    tvpregDietary_01: 'Do you have any food allergies or intolerance?',
    tvpregDietary_02: 'No',
    tvpregDietary_03: 'Having Food Allergys',
    tvpregDietary_04: 'Having Food Intolerance',
    tvpregDietary_05: 'Please choose foods that are allergic or intolerant (multiple choices are possible)',
    tvpregDietary_06: 'Eggs',
    tvpregDietary_07: 'Milk',
    tvpregDietary_08: 'Fruit Kernels',
    tvpregDietary_09: 'Wheat Gluten',
    tvpregDietary_10: 'Crustaceans (shelled seafood such as shrimp, crabs, and snails)',
    tvpregDietary_11: 'Other',
    tvpregDietary_12: 'Has the doctor suggested limiting your diet? (Multiple options available)',
    tvpregDietary_13: 'No',
    tvpregDietary_14: 'potassium',
    tvpregDietary_15: 'phosphorus',
    tvpregDietary_16: 'sodium',
    tvpregDietary_17: 'protein',
    tvpregDietary_18: 'Purine',
    tvpregDietary_19: 'Vitamin K',
    tvpregDietary_20: 'Moisture',
    tvpregDietary_21: 'everyday',
    tvpregDietary_22: 'Liters',
    tvpregDietary_23: 'Are you a vegetarian? (Multiple choices)',
    tvpregDietary_24: 'No',
    tvpregDietary_25: 'Holomorph',
    tvpregDietary_26: 'Egg milk element',
    tvpregDietary_27: 'Egg element',
    tvpregDietary_28: 'Milk element',
    tvpregDietary_29: 'Have you taken any nutritional supplements?',
    tvpregDietary_30: 'Yes, please specify',
    tvpregDietary_31: 'Do you have any drinking habits?',
    tvpregDietary_32: '(Please refer to the remarks for reference information on the portion size)',
    tvpregDietary_33: 'How many alcoholic beverages do you consume per week?',
    tvpregDietary_34: 'copies',
    tvpregDietary_35: 'Smoking?',
    tvpregDietary_36: 'Note: Reference materials for portion size',
    tvpregDietary_37: 'Do you have a partial diet?',
    tvpregDietary_38: 'A portion of alcohol=',
    tvpregDietary_39: 'A can of beer (approximately 330 milliliters)',
    tvpregDietary_40: 'Apple cider 275 milliliters',
    tvpregDietary_41: 'Red wine/Baijiu/champagne 125ml',
    tvpregDietary_42: '75 milliliters of plum wine',
    tvpregDietary_43: '30 milliliters of liquor',
    tvpregDietary_44: 'Chinese liquor/Baijiu 25ml',
    Remark: 'Remark',
    tvpregFoodselection_1: 'How many times do you eat high fat starch per week?',
    tvpregFoodselection_2: '(such as river noodles/instant noodles/cup noodles/noodles/Fried Rice/fried noodles/fried river, etc.)',
    tvpregFoodselection_3: 'How many times do you eat filling/sweet bread per week?',
    tvpregFoodselection_4: '(such as pineapple buns, chicken tail buns, Mexican buns, etc.)',
    tvpregFoodselection_5: 'times',
    tvpregFoodselection_6: 'Peel and remove visible animal fat when eating poultry?',
    tvpregFoodselection_7: 'No',
    tvpregFoodselection_8: 'Very Little',
    tvpregFoodselection_9: 'Sometimes',
    tvpregFoodselection_10: 'Often',
    tvpregFoodselection_11: 'How many times do you eat high fat meat per week?',
    tvpregFoodselection_12: 'How many times do you eat high fat meat per week?',
    tvpregFoodselection_13: '(such as pork ribs and chicken feet, animal skin/fat paste, fish belly/pork belly, fish head, etc.)',
    tvpregFoodselection_14: 'How many times do you eat processed meat per week?',
    tvpregFoodselection_15: '(such as sausage, ham, smoked meat, lunch meat, etc.)',
    tvpregFoodselection_16: 'How many times a week do you drink old soup made with bone or whole chicken ingredients?',
    tvpregFoodselection_17: 'How many times a week do you eat fried food?',
    tvpregFoodselection_18: 'How many times a week do you consume the following fatty foods?',
    tvpregFoodselection_19: '(such as butter/lard/coconut oil/palm oil, etc.)',
    tvpregFoodselection_20: 'How many times do you eat deep-sea fish per week?',
    tvpregFoodselection_21: '(such as salmon, jing fish, cang fish, bigeye fish, silver cod, etc.)',
    tvpregFoodselection_22: 'How many servings of dairy products/other calcium substitutes are consumed daily?',
    tvpregFoodselection_23: '(such as high calcium soybean milk/almond milk, etc.)',
    tvpregFoodselection_24: 'Choose low-fat dairy products?',
    tvpregFoodselection_25: 'How many times a week do you consume sugary drinks?',
    tvpregFoodselection_26: '(such as soda/boxed bottled drinks/lactic acid cheese drinks/sweetened milk tea/sweetened coffee, etc.)',
    tvpregFoodselection_27: 'How many times do you drink freshly squeezed fruit juice and pulp per week?',
    tvpregFoodselection_28: 'How many times a week do you eat desserts?',
    tvpregFoodselection_29: '(such as cake, cookies, ice cream, syrup, chocolate, candy, etc.)',
    tvpregFoodselection_30: 'Will additional salt/oil/sauce be added to the meal during eating?',
    tvpregFoodselection_31: 'Will you use juice to make rice?',
    tvpregEating_1: 'How much is usually full per meal?',
    tvpregEating_2: 'Less than 60%',
    tvpregEating_3: '70% to 80%',
    tvpregEating_4: 'At least 90%',
    tvpregEating_5: 'Do you eat within 3 hours before bedtime?',
    tvpregEating_6: '(If there is a habit of spending the night)',
    tvpregEating_7: 'How many times a week do you skip breakfast?',
    tvpregEating_8: 'How many meals do you eat every day?',
    tvpregEating_9: '1 meal',
    tvpregEating_10: '2 meals',
    tvpregEating_11: '3 meals',
    tvpregEating_12: 'meals',
    tvpregEating_13: 'How long does it take to complete each meal?',
    tvpregEating_14: 'Do you need to go out for meals frequently?',
    tvpregEating_15: 'No need',
    tvpregEating_16: 'Yes, please indicate the reason:',
    tvpregEating_17: 'Who is responsible for cooking at home? (Multiple options available)',
    tvpregEating_18: 'No',
    tvpregEating_19: 'oneself',
    tvpregEating_20: 'family',
    tvpregEating_21: 'worker',
    tvpregEating_22: 'minutes',
    tvpregExercise_1: 'Is there any reason why exercise is not suitable?',
    tvpregExercise_2: 'No',
    tvpregExercise_3: 'Have',
    tvpregExercise_4: 'have no idea',
    tvpregExercise_5: 'Do you currently engage in any exercise?',
    tvpregExercise_6: 'Please fill in the type of activity (if any)',
    tvpregExercise_7: 'Please fill in the number of activities per week (if any)',
    tvpregExercise_8: 'Please fill in the time of each activity (minutes/hour) (if any)',
    tvpregExercise_9: 'Is there anyone who encourages you to exercise regularly?',
    tvpregExercise_10: 'Are there any sports facilities or places for exercise near the residence?',
    tvpregExercise_11: 'park',
    tvpregExercise_12: 'gym',
    tvpregExercise_13: 'Other',
    tvpregExercise_14: 'Average daily steps?',
    tvpregExercise_15: 'daily average',
    tvpregExercise_16: 'steps',
    tvpregExercise_17: '(Multiple options available)',
    tvpregexpect_1: 'What do you expect to receive after meeting a therapist?',
    tvpregexpect_2: 'amendment of health',
    tvpregexpect_3: 'Increase nutrition knowledge',
    tvpregexpect_4: 'Other',
    tvpregexpect_5: 'If participating in weight management',
    tvpregexpect_6: 'How many pounds are expected to be subtracted/added?',
    tvpregexpect_7: 'minus',
    tvpregexpect_8: 'increase',
    tvpregexpect_9: 'pounds',
    tvpregexpect_10: 'Which parts do you want to lose weight the most?',
    tvpregexpect_11: 'arm',
    tvpregexpect_12: 'belly',
    tvpregexpect_13: 'foot',
    tvpregexpect_14: 'Other',
    tvpregexpect_15: 'I hereby declare that all the data listed above have been truthfully filled out to the best of my knowledge and have been verified by me to be correct.',
    tvpreg01: 'We have detected that you are already a member of ours. Would you like to directly use this membership information to fill it out',
    tvpreg02: 'Yes, using',
    tvpreg03: 'Reregister',
    preview: 'Preview',
    Confirmsubmission: 'Confirm submission',
    UpdateData: 'Update Data',
    CancelModify: 'Cancel',
    Abnormal: 'Abnormal'
  }
};
