export default {
  lang: '繁',
  test: '测试',
  AddToCart: '加入購物車',
  changeLangSuccess: '語言切換成功！',
  changeCurSuccess: '貨幣切換成功！',
  Input: {
    email: '請輸入正確的郵箱',
    number: '請輸入數字',
    input: '請輸入',
    text: '',
    date: '請選擇日期',
    password: '兩次輸入密碼不一致',
    textarea: '',
    emailNotFound: '郵箱不存在',
    logopassword: '請輸入密碼',
    confirmpassword: '兩次輸入密碼不一致',
    nomessage: '暫無數據',
    phoneincorrect: '電話號碼格式不正確'
  },
  Message: {
    Logout: '您尚未登陸或已登錄超時，請重新登陸',
    Message: '消息',
    Confirm: '確認',
    Cancel: '關閉',
    SucceedInOperating: '操作成功',
    SavedSuccessfully: '保存成功',
    Loading: '頁面正在加載，請稍候',
    ViewDetail: '瞭解更多',
    FollowUs: '關注我們',
    Evidence: '憑據',
    UploadEvidence: '上傳憑據',
    OrderNumber: '訂單編號',
    Remark: '備註',
    Image: '圖片',
    SubmitSuccess: '提交成功',
    SubmitFailed: '提交失敗',
    DeliveryDate: '送貨日期',
    DeliveryTime: '送貨時段',
    DeliveryInstructions: '送貨指示',
    DeliveryMark: '預訂請預早⼀天，每天晚上10 點截單，套餐滿800 包市區送貨。10點前⼊數⽅可隔天送貨。',
    All: '全部',
    noRMBStripe: '貨幣RMB不支持Stripe支付，請選擇其他支付方式或切換貨幣模式'
  },
  Account: {
    MemberCenter: '會員中心',
    MemberInformation: '用戶信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最愛',
    MyOrder: '我的訂單',
    DeliveryAddress: '送貨地址',
    Logout: '登出',
    Welcome: '歡迎!',
    SISA: '選擇成功',
    PersonCenter: '個人中心',
    HealthButler: '健康管家'
  },
  RegNPay: {
    BackText: '返回修改',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確認送出',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案'
  },
  product: {
    ProductCode: '產品代碼',
    buy: '立即購買',
    addToCart: '加入購物車',
    buyNow: '立即購買',
    favorite: '加入我的清單',
    priceTitle: '價格',
    countTitle: '數量',
    youWouldLike: '你可能喜歡',
    moreTips: '點擊加載更多',
    none: '沒有了',
    noneInCat: '當前目錄無產品，請選擇其他目錄',
    notifyMe: '到貨通知',
    purchasing: '采購中',
    comments: { title: '商品評論' },
    detail: '產品詳情',
    overView: '產品簡介',
    specification: '使用説明',
    attr: { 0: '尺寸', 1: '顔色' },
    SaleOut: '售罄',
    offSale: '已下架',
    Recommend: '會員推薦',
    Remarks: '備注',
    EnterRemaks: '請填寫備注',
    up: '上一個',
    down: '下一個',
    logouted: '您已退出登錄！',
    loginow: '現在重新登陸？',
    successInAdding: '添加成功',
    successInRemoving: '取消成功',
    ProductIntro: '產品介紹',
    ProductIntroduction: '產品介紹',
    UserReviews: '用戶評論',
    OwnerReply: '商家回復',
    per: '第',
    page: '頁',
    Screening: '篩選',
    Resetall: '重置所有篩選條件',
    PriceHL: '價格從高至低',
    PriceLH: '價格從低至高',
    Producttitle: '產品列表',
    Paixu: '排序',
    Total: '共',
    Product: '個產品',
    ProductDetail: '產品詳情',
    Unit: '單位'
  },
  Shoppingcart: {
    ShoppingcartTitle: '購物車',
    Merchant: '商家',
    Product: '產品',
    Quantity: '數量',
    Price: '價格',
    Total: '商品總價',
    CheckoutList: '確認訂單',
    Checkout: '安全結算',
    goTOCart: '前往購物車',
    vat: '包含增值稅',
    None: '購物車裏空空如也，現在去購買？',
    more: '更多',
    hide: '收起'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '會員登入',
    NewUser: '新用戶',
    RegNow: '現在注冊',
    ForgetPwd: '忘記密碼',
    RememberMe: '記住我',
    FaceBookUse: '使用facebook賬戶登錄',
    UserName: '登錄用戶名/郵箱地址',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確郵箱地址',
    LoginPwd: '請輸入密碼',
    LoginoldPwd: '密碼不正確',
    Loginerror: '賬號或密碼不正確',
    LoginSucceeded: '登入成功！',
    Account: '個人中心'
  },
  Register: {
    RegisterTitle: '註冊會員',
    UserEmail: '郵箱',
    or: '或',
    UserRegPassword: '密碼',
    UserNewPassword: '新密碼',
    UserOldPassword: '舊密碼',
    UserRegTips: '最少6個由字母或數字組成的字符',
    UserConfirmPassword: '確認密碼',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    UserBirthDate: '出生日期',
    UserGender: '性別',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '語言',
    UserLanguageE: '英文',
    UserLanguageT: '繁體',
    UserLanguageS: '簡體',
    RegisterBtn: '註冊',
    RegisterAgree: '我同意購買條款和使用條款',
    RegisterEmail: '郵箱不能為空',
    RegisterEmail01: '請輸入正式的郵箱格式',
    RegisterPassword: '請輸入密碼',
    RegisterNewPassword: '請再次輸入密碼',
    RegisterNewPassword01: '兩次輸入密碼不一致',
    RegisterFirstName: '請輸入您的姓名',
    RegisterLastName: '請輸入您的名稱',
    RegisterAgree_check: '請勾選',
    RegisterMobile: '請輸入您的手提電話',
    RegisterSuccess: '註冊成功',
    RegisterFail: '註冊失敗',
    RegisterWelcome: '恭喜您,註冊成功',
    RegisterTips: '此電子郵箱尚未註冊，請立即註冊成為會員！',
    Login: '跳轉登錄',
    Registerdigital: '長度由8至20個字符 須包含大寫字母、小寫字母及數字'
  },
  DeliveryAddress: {
    AddAddress: '新增地址',
    ChooseDeliveryAddress: '選擇送貨地址',
    EditBtn: '编辑',
    DeleteBtn: '删除',
    AddDeliveryAddress: '添加新的收貨地址',
    PostalCode: '郵政編碼',
    Area: '地區',
    Province: '省份',
    District: '區份',
    Address: '地址',
    DefaultAddress: '設為默認送貨地址?',
    SaveBtn: '保存',
    ResetBtn: '重置',
    CloseBtn: '关闭',
    YesBtn: '是',
    NoBtn: '否',
    AddFirstName: '名字',
    AddLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    Mobile: '手提電話',
    Addressprovince: '請選擇省份',
    AddressArea: '請選擇地區',
    Detail: '街道/屋苑/樓層/室'
  },
  MyCoupon: {
    MyCoupon: '我的優惠券',
    Coupon: '優惠券',
    PeriodOfValidity: '有效期',
    NoCoupon: '暫無優惠券',
    CouponStatus: '優惠券狀態',
    SelectCouponStatus: '請選擇優惠券狀態',
    TotalNum: '共有',
    Piece: '張',
    NotUse: '可用',
    DoNotUse: '不可用',
    Used: '已用',
    Among: '其中',
    All: '全部'
  },
  MyFavorite: {
    MyFavorite: '我的最愛',
    LearnMore: '瞭解更多',
    RemoveProduct: '移除產品',
    RemoveSuccess: '移除成功',
    AddSuccess: '加入成功'
  },
  Notification: {
    MyMessages: '我的信箱',
    MsgList: '消息列表',
    TotalRecQtyWithSymbol: '總記錄數 ：',
    UnreadWithSymbol: '未讀記錄數 ：',
    MsgUnreadOnly: '只看未讀消息',
    MsgMarkAsRead: '標記為已讀',
    CheckAllOrNot: '全選/反選',
    MsgSender: '發信人',
    MsgSubject: '主題',
    MsgSenderDate: '發信時間',
    MsgReadStatus: '狀態',
    Details: '瞭解詳情',
    More: '更多',
    Back: '返回',
    MsgContent: '内容',
    Send: '發送',
    Unread: '未讀',
    Read: '已讀',
    UpdateSucceeded: '更新成功!',
    Check: '選擇',
    Id: '序號'
  },
  Forgetpassword: {
    ForgetPassword: '忘記密碼',
    ResetPwdTips: '請輸入您用於註冊的電子郵件，然後點擊“下一步”繼續',
    LoginNameEmail: '郵箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密碼已發送到您的郵箱，請查看。',
    NotEmail: '收不到重設密碼電郵',
    ToReEnter: '按此重新填寫',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確的郵箱地址',
    ResetPwd: '重設密碼'
  },
  MemberInfo: {
    MemberInfoTitle: '用戶信息',
    UserAccount: '用戶賬號',
    ModifyPassword: '修改密碼',
    EnterUserName: '請輸入名字',
    EnterUserLastName: '請輸入姓氏',
    EnterUserPhone: '請輸入聯係電話',
    EnterUserBrithDate: '請選擇出生日期',
    EnterOldPwd: '請輸入密碼',
    EnterNewPwd: '請輸入新密碼',
    EnterComfirmPwd: '請輸入確認密碼',
    EnterNumber: '請輸入數字',
    PwdNotCorrect: '兩次密碼不一致',
    AgreeEmail: '本人同意天才兒童書店透過電郵地址向我發送推廣資訊',
    DDMM: '日/月'
  },
  Order: {
    OrderTitle: '我的訂單',
    OrderDetail: '訂單詳情',
    OrderPay: '立即支付',
    OrderCancel: '訂單取消',
    OrderComplete: '訂單完成',
    OrderClose: '訂單關閉',
    CartSubtotal: '小計',
    DeliveryCharge: '運輸費用',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    OrderDateTime: '訂單日期及時間 ',
    OrderNumber: '訂單編號',
    OrderSearch: '訂單搜索',
    EnterProductName: '請輸入產品名稱',
    Items: '項',
    OrderComment: '評價',
    PromotionCode: '促銷碼',
    RecommendationCode: '會員推薦碼',
    CommodityPrice: '商品總價',
    Full: '買滿',
    Minus: '減',
    Hit: '減',
    Precent: '%',
    Discount: '優惠價格',
    Cancel: '取消',
    Paid: '已付款',
    Unpaid: '未付款'
  },
  Concessions: {
    Cannot: '優惠券与优惠码不可不可同时使用'
  },
  Payment: {
    ReturnHomePage: '返回首頁',
    PayStatus: '支付狀態',
    PayFailTips: '本次支付失敗，請檢查您的支付戶口后重新嘗試。',
    PaysuccessTips: '本次支付成功'
  },
  CheckOut: {
    Ordersummary: '訂單摘要',
    Input: '請輸入',
    expiryDate: '過期日期',
    awsl: 'awsl',
    expressError: '快遞異常！請選擇其他快遞。',
    pay: '支付',
    CheckOutTitle: '確認訂單',
    Merchant: '商家',
    Product: '產品',
    Qty: '數量',
    Price: '價錢',
    SplitDelivery: '拆單',
    ShippingMethod: '送貨方式',
    PleaseSelect: '請選擇',
    Selected: '已選擇',
    Name: '名字',
    Phone: '電話',
    Address: '地址',
    Pickup: '自取',
    Change: '新增或更改地址',
    DeliveryCharge: '運輸費用',
    SubTotal: '商品小計',
    PayBy: '支付方式',
    Couponcode: '優惠碼',
    confirm: '確認',
    Total: '共',
    BacktoShoppingCart: '返回購物車',
    Last: '上一頁',
    Checkout: '確認訂單',
    Next: '下一頁',
    Delivery: '配送方式與運費',
    SelectDeliveryAddress: '選擇送貨地址',
    LastName: '姓氏',
    Edit: '更改地址',
    Delete: '刪除地址',
    Select: '選取',
    NewAddress: '添加新的收貨地址',
    PickUp: '自取',
    FirstName: '名字',
    ZipCode: '郵政編碼',
    Mobile: '手機',
    Country: '地區',
    Province: '地區/省份',
    City: '區份',
    Close: '關閉',
    PickupDate: '自取日期',
    PickupTime: '自取時間',
    Morning: '早上',
    Afternoon: '下午',
    PickAddressError: '自提信息必須完整',
    AddressLock: '地址已被選中不可刪除',
    CompanyName: '門店',
    CompanyPhone: '門店電話',
    CompanyAddress: '門店地址',
    PickupAddress: '自取地址',
    Save: '保存',
    PleaseChoose: '請選擇',
    PleaseSelectdate: '請選擇日期',
    PleaseSelecttime: '請選擇時間',
    ConfirmationOfOrder: '確認訂單',
    Concessions: '優惠信息',
    PaymentMethod: '支付方式',
    Complete: '完成',
    OrderPreview: '訂單預覽',
    CandP: '優惠券與促銷碼不能同時使用',
    promotionCodeError: '請輸入正確的促銷碼',
    Use: '使用',
    As: '作爲',
    PaymentMethodError: '支付方式尚未选择！',
    OrderSent: '訂單已發出',
    PaymentProcess: '請繼續完成付款程序'
  },
  Address: {
    Country: '請選擇國家',
    Province: '請選擇省份',
    Address: '請填寫詳細地址'
  },
  MemberPoints: {
    MemberPoints: '會員積分',
    PointsDetail: '積分明細',
    PointsIncome: '積分收入',
    PointsSpending: '積分支出',
    OrderTime: '訂單時間',
    OrderNo: '訂單編號',
    Income: '收入',
    Spending: '支出',
    IncomeSpending: '收入/支出',
    DetailedDescription: '詳細説明',
    TotalPoints: '總積分',
    Hello: '您好',
    Equals: '等於',
    Integral: '積分',
    PointsTips: '積分使用常見問題',
    PointsType: '積分類別',
    Points: '積分',
    PointsMode: 'PointsMode'
  },
  Cms: {
    AboutUS: '關於我們',
    OurStores: '門市',
    BusinessHours: '營業時間',
    BigSales: '熱銷產品',
    WhatNews: '今期流行'
  },
  promotion: {
    enter: '點擊進入',
    main: '天才兒童最佳之選',
    monterssori: {
      title1: '蒙特梭利教學法',
      title2: '培養幼兒自覺主動的學習和探索精神',
      banner1: '做自己的老師',
      banner2: '讓兒童自發性地主動學習',
      banner3: '自己構建完善的人格'
    }
  },
  home: {
    News: '新聞',
    More: '更多',
    Category: '產品目錄',
    Weaccept: '我們接受',
    Order: '訂購',
    TelSearch: '電話查詢',
    AllProduct: '全部產品',
    ResetProduct: '重置',
    Address: '地址',
    ViewDetail: '查看詳情',
    Screening: '篩選',
    Resetall: '重置所有篩選條件',
    PriceHL: '價格從高至低',
    PriceLH: '價格從低至高',
    Thatsall: '以上是全部內容',
    WeAcceptPay: '我們接受以下付款方式'
  },
  Action: {
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情',
    PaymentSuccessTips: '支付成功',
    PaymentFailTips: '支付失敗',
    Checkout: '確認訂單',
    Save: '保存',
    PromotionCodeTips: '請輸入優惠碼',
    Share: '立即分享',
    LoadMore: '加載更多',
    FacebookLogin: 'Facebook 登入',
    HotPot: '火鍋類',
    BBQ: '燒烤類',
    Dishes: '餸菜類',
    Package: '套餐',
    ProductSearch: '產品搜尋',
    Total: '共',
    Products: '個產品',
    NowBook: '現在預約',
    NowOrder: '立即預約'
  },
  accountHeader: {
    MemberFile: '會員檔案',
    Favorite: '喜愛項目',
    GrantsCredits: '積分獎勵',
    MyOrder: '我的訂單',
    ECoupon: '電子禮券',
    MembershipDiscount: '會員優惠'
  },
  regnpay: {
    successful: '感謝您的提交',
    reply: '我們會儘快回復您',
    ContactUs: '聯絡我們'
  },
  messageTips: {
    NoContent: '暫無內容',
    NoComments: '暫無評論',
    NoProduct: '對不起，沒有你搜索的產品'
  },
  tvp: {
    MyHealthButler: '我的健康管家',
    tvpinformation: '個人信息',
    tvpinformation01: '身體狀況',
    tvpregDietary: '飲食評估',
    tvpregFood: '食物日記',
    tvpregFoodselection: '食物選擇',
    tvpregEating: '飲食習慣',
    tvpregExercise: '運動習慣',
    tvpregexpect: '期望',
    tvptime: '需時10-15分鐘完成',
    tvpregFood_1: '(請填上最近之飲食情況)',
    tvpregFood_2: '早餐',
    tvpregFood_3: '時間',
    tvpregFood_4: '地點',
    tvpregFood_5: '食物名稱, 煮法及詳細份量',
    tvpregFood_6: '午餐',
    tvpregFood_7: '下午茶',
    tvpregFood_8: '晚餐',
    tvpregFood_9: '晚點',
    tvpregFood_10: '早點',
    Email: '郵箱',
    setEmail: '你輸入的郵箱已存在,可以输入密码直接登录',
    Password: '密碼',
    tvpregchinesename: '中文全名',
    tvpregengname: '英文全名',
    tvpregalias: '別名',
    Gender: '性別',
    Male: '男',
    Female: '女',
    BirthDate: '出生日期',
    tvpregAge: '歲數',
    tvpregphone: '聯絡電話',
    tvpregwhatsapp: '電話是否可以WhatsApp聯絡',
    Yes: '是',
    No: '否',
    tvpregMarital: '婚姻狀況',
    Pleaseselect: '請選擇',
    tvpregOccupation: '職業',
    Occupation_1: '全職',
    Occupation_2: '兼職',
    Occupation_3: '退休人士',
    Occupation_4: '家庭主婦',
    Occupation_5: '其他',
    tvpreglevel: '教育水平',
    Educational_1: '大專或以上',
    Educational_2: '中學',
    Educational_3: '小學',
    Educational_4: '其他',
    modification: '提交修改',
    information: '修改信息',
    tvpregPrev: '上一頁',
    tvpregNext: '下一頁',
    tvpregMedicalrecord: '病歷記錄',
    tvpregDrugs: '正在服用的藥物',
    tvpregheight: '身高(如不知道請留空)',
    tvpregheight01: '厘米',
    tvpregWeight: '體重(如不知道請留空)',
    tvpregWeight01: '公斤',
    tvpregpast: '過去三個月有顯著的體重改變嗎？',
    tvpregDetailed15: '如有，請詳細說明。',
    tvpregpast01: '增重',
    tvpregpast02: '減輕',
    tvpregpast03: '沒有',
    tvpregLose16: '曾否減肥？',
    tvpregLose16_01: '有',
    tvpregLose16_02: '沒有',
    tvpregNutrition17: '曾否接受其它物理治療服務',
    tvpreg18_1: '上血壓(mmHg)(如有)',
    tvpreg18_2: '下血壓(mmHg)(如有)',
    tvpregblood: '血液測試(如有)',
    tvpregblood19: '檢測日期',
    tvpregblood19_1: '總膽固醇 (mmol/L)',
    tvpregblood19_2: '空腹血糖 (mmol/L)',
    tvpregblood19_3: '三酸甘油脂 (mmol/L)',
    tvpregblood19_4: '2小時血糖 (mmol/L)',
    tvpregblood19_5: '高密度膽固醇 (mmol/L)',
    tvpregblood19_6: '低密度膽固醇 (mmol/L)',
    tvpregblood19_7: '糖化血紅素 (%)',
    tvpregblood19_8: '尿酸 (mmol/L)',
    tvpregblood19_9: '其它備註',
    tvpregDietary_01: '有沒有食物過敏或不耐受?',
    tvpregDietary_02: '沒有',
    tvpregDietary_03: '有食物過敏 (Food Allergy)',
    tvpregDietary_04: '有食物不耐受(Food Intolerance)',
    tvpregDietary_05: '請選擇過敏或不耐受食物(可多選)',
    tvpregDietary_06: '雞蛋',
    tvpregDietary_07: '牛奶',
    tvpregDietary_08: '果仁',
    tvpregDietary_09: '小麥麩質',
    tvpregDietary_10: '甲殼類 (蝦、蟹、螺等有殼海鮮)',
    tvpregDietary_11: '其他',
    tvpregDietary_12: '醫生有沒有建議您限制飲食?(可多選)',
    tvpregDietary_13: '沒有',
    tvpregDietary_14: '鉀',
    tvpregDietary_15: '磷',
    tvpregDietary_16: '鈉',
    tvpregDietary_17: '蛋白質',
    tvpregDietary_18: '嘌呤 (普林)',
    tvpregDietary_19: '維他命K',
    tvpregDietary_20: '水份',
    tvpregDietary_21: '每日',
    tvpregDietary_22: '公升',
    tvpregDietary_23: '是否素食者?(多選)',
    tvpregDietary_24: '不是',
    tvpregDietary_25: '全素',
    tvpregDietary_26: '蛋奶素',
    tvpregDietary_27: '蛋素',
    tvpregDietary_28: '奶素',
    tvpregDietary_29: '有沒有服食營養補充品?',
    tvpregDietary_30: '有，請註明',
    tvpregDietary_31: '有沒有飲酒習慣?',
    tvpregDietary_32: '(份量參考資料可參考備註)',
    tvpregDietary_33: '每星期攝取多少份酒精飲品?',
    tvpregDietary_34: '份',
    tvpregDietary_35: '吸煙嗎?',
    tvpregDietary_36: '備註: 份量參考資料',
    tvpregDietary_37: '有沒有偏食?',
    tvpregDietary_38: '一份酒精=',
    tvpregDietary_39: '啤酒一罐 (大約330毫升)',
    tvpregDietary_40: '蘋果酒 275毫升',
    tvpregDietary_41: '紅酒/白酒/香檳 125毫升',
    tvpregDietary_42: '梅酒 75毫升',
    tvpregDietary_43: '烈酒 30毫升',
    tvpregDietary_44: '中式烈酒/白酒 25毫升',
    Remark: '備註',
    tvpregFoodselection_1: '每星期進食多少次高脂肪澱粉質?',
    tvpregFoodselection_2: '(如河粉/即食麵/杯麵/伊麵/炒飯/炒麵/炒河等)',
    tvpregFoodselection_3: '每星期進食多少次有餡/甜麵包?',
    tvpregFoodselection_4: '(如菠蘿包、雞尾包、墨西哥包等)',
    tvpregFoodselection_5: '次',
    tvpregFoodselection_6: '進食家禽時去皮及去除可見動物脂肪?',
    tvpregFoodselection_7: '不會',
    tvpregFoodselection_8: '甚少',
    tvpregFoodselection_9: '有時',
    tvpregFoodselection_10: '經常',
    tvpregFoodselection_11: '每星期進食多少次高脂肪肉類?',
    tvpregFoodselection_12: '每星期進食多少次高脂肪肉類?',
    tvpregFoodselection_13: '(如排骨鳳爪、動物皮層/肥膏、魚腩/腩肉、魚頭等)',
    tvpregFoodselection_14: '每星期進食多少次加工肉類?',
    tvpregFoodselection_15: '(如腸仔、火腿、煙肉、午餐肉等)',
    tvpregFoodselection_16: '每星期多少次飲用用骨或全雞材料煲的老火湯?',
    tvpregFoodselection_17: '每星期多少次進食煎炸食物?',
    tvpregFoodselection_18: '一星期多少次進食以下的油脂類食物?',
    tvpregFoodselection_19: '(如牛油/豬油/椰子油/棕櫚油等)',
    tvpregFoodselection_20: '每星期進食多少次深海魚類?',
    tvpregFoodselection_21: '(如三文魚、靖魚、倉魚、大眼魚、銀鱈魚等)',
    tvpregFoodselection_22: '每日進食多少份奶類產品/其他含鈣取代品?',
    tvpregFoodselection_23: '(如高鈣豆漿/杏仁奶等)',
    tvpregFoodselection_24: '選擇低脂奶類產品?',
    tvpregFoodselection_25: '每星期多少次飲用添加糖飲品?',
    tvpregFoodselection_26: '(如汽水/盒裝樽裝飲品/乳酸乳酪飲品/加糖奶茶/加糖咖啡等)',
    tvpregFoodselection_27: '每星期飲用鮮榨果汁連果肉的次數?',
    tvpregFoodselection_28: '每星期多少次進食甜點?',
    tvpregFoodselection_29: '(如蛋糕、曲奇、雪糕、糖水、朱古力、糖果等)',
    tvpregFoodselection_30: '會否在進食時為餐點加入額外的鹽/油/醬汁?',
    tvpregFoodselection_31: '會否「用汁撈飯」?',
    tvpregEating_1: '每餐通常有多少成飽?',
    tvpregEating_2: '少於6成',
    tvpregEating_3: '7成至8成',
    tvpregEating_4: '最少9成',
    tvpregEating_5: '睡前3小時內會否進食?',
    tvpregEating_6: '(如有宵夜習慣)',
    tvpregEating_7: '一星期多少次不吃早餐?',
    tvpregEating_8: '每天食多少餐?',
    tvpregEating_9: '１餐',
    tvpregEating_10: '2 餐',
    tvpregEating_11: '3 餐',
    tvpregEating_12: '餐',
    tvpregEating_13: '每餐需多少時間完成?',
    tvpregEating_14: '是否需要經常外出用膳?',
    tvpregEating_15: '不需要',
    tvpregEating_16: '需要，請註明原因:',
    tvpregEating_17: '家中誰負責煮食?(可多選)',
    tvpregEating_18: '沒有',
    tvpregEating_19: '自己',
    tvpregEating_20: '家人',
    tvpregEating_21: '工人',
    tvpregEating_22: '分鐘',
    tvpregExercise_1: '有沒有原因不適宜做運動?',
    tvpregExercise_2: '沒有',
    tvpregExercise_3: '有',
    tvpregExercise_4: '不知道',
    tvpregExercise_5: '現時有沒有做任何運動?',
    tvpregExercise_6: '請填寫活動種類 (如有)',
    tvpregExercise_7: '請填寫每星期活動次數 (如有)',
    tvpregExercise_8: '請填寫每次活動時間 (分鐘/小時) (如有)',
    tvpregExercise_9: '有沒有鼓勵您定期運動的人?',
    tvpregExercise_10: '住所附近有沒有運動設施或可做運動的地方?',
    tvpregExercise_11: '公園',
    tvpregExercise_12: '健身房',
    tvpregExercise_13: '其他',
    tvpregExercise_14: '每日平均步數?',
    tvpregExercise_15: '每日平均',
    tvpregExercise_16: '步',
    tvpregExercise_17: '(可多選)',
    tvpregexpect_1: '期望見治療師後得到什麼?',
    tvpregexpect_2: '改善健康',
    tvpregexpect_3: '增加營養知識',
    tvpregexpect_4: '其他',
    tvpregexpect_5: '若參加體重管理',
    tvpregexpect_6: '期望可減去/增加多少磅?',
    tvpregexpect_7: '減去',
    tvpregexpect_8: '增加',
    tvpregexpect_9: '磅',
    tvpregexpect_10: '哪些部位最想瘦?',
    tvpregexpect_11: '手臂',
    tvpregexpect_12: '肚腩',
    tvpregexpect_13: '腳',
    tvpregexpect_14: '其他',
    tvpregexpect_15: '本人謹此聲明以上所列全部資料乃就本人所知一切據實填報, 並經本人核實正確無誤。',
    tvpreg01: '檢測到您已是我們的會員，是否直接使用本會員資料進行填寫',
    tvpreg02: '是的，使用',
    tvpreg03: '重新註冊',
    preview: '預覽',
    Confirmsubmission: '確認提交',
    UpdateData: '更新數據',
    CancelModify: '取消修改',
    Abnormal: '数值異常'
  }
};
